import BaseCard from "./BaseCard";
import "./StartingSoonCard.css";
import iconVarsity from '../../assets/icons/varsity.svg';
import { Datetime } from "../../core/l10n";
import { Game } from "../../types/game";

type StartingSoonCardProps = Game & {
};

export default function StartingSoonCard({ home, away, starts_at }: StartingSoonCardProps) {
  return (
    <BaseCard innerStyle="StartingSoonCard-inner">
      <div className="StartingSoonCard-teams">
        <img
          src={home.logo}
          alt={home.short_name}
          className="StartingSoonCard-team-logo"
          />

        <p className="StartingSoonCard-title">
          <span className="StartingSoonCard-title-team">{away.short_name}</span>
          {` @ `}
          <span className="StartingSoonCard-title-team">{home.short_name}</span>
        </p>

        <img
          src={away.logo}
          alt={away.short_name}
          className="StartingSoonCard-team-logo"
          />
      </div>

      <p className="StartingSoonCard-datetime">
        {starts_at.local().format(Datetime.DATE)}
        <br/>
        {starts_at.local().format(Datetime.TIME)}
      </p>

      <a href="https://thevarsitynetwork.com" className="button">
        <img
          src={iconVarsity}
          alt="The Varsity Network"
          className="button-icon"
          />
      </a>
    </BaseCard>
  );
}