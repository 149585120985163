import dayjs from "dayjs"
import './Schedule.css';
import LiveNowCard from '../components/cards/LiveNowCard';
import StartingSoonCard from '../components/cards/StartingSoonCard';
import { Game } from "../types/game";

type ScheduleProps = {
  onSelect: (game: Game) => void;
};

function Schedule({ onSelect }: ScheduleProps) {
  const GAMES = [ // NOTE: this is inside the component because dayjs needs to boot
    {
      id: 1,
      home: {
        long_name: 'Longbeach State University',
        short_name: 'LBSC',
        logo: 'https://clients.sidearmsports.com/sidearm_files/logos/big_west/long_beach_state.png',
      },
      away: {
        long_name: 'University of Southern California',
        short_name: 'USC',
        logo: 'https://longbeachstate.com/services/logo_handler.ashx?image_path=/images/logos/usc-primary-200x200.png&thumb_prefix=rp_sched',
      },
      venue: "Longbeach State University",
      starts_at: dayjs('2024-12-31 00:00:00').utc(),
    },
  ];

  const live = [ ...GAMES ] as Game[];
  const soon = [ ...GAMES ] as Game[];

  return (
    <div className="Schedule">
      {live.map((game) => (
        <LiveNowCard
          key={game.id}
          {...game}
          onClick={() => onSelect(game)}
          />
      ))}

      {soon.length > 0
        ? <>
            <p className="Schedule-heading">STARTING SOON</p>

            {soon.map((game) => (
              <StartingSoonCard
                key={game.id}
                {...game}
                />
            ))}
          </>
        : null
      }
    </div>
  );
}

export default Schedule;
