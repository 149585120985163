const CONNECTION_TIMEOUT = 30_000; // overall timeout between start() being called and `RTCPeerConnection.connectionState === 'connected'`
const ICE_GATHERING_TIMEOUT = 15_000; // timeout for ICE candidate gathering
const CONNECTION_CLOSURE_TIMEOUT = 5_000; // timeout between stop() being called and `RTCPeerConnection.connectionState === 'closed'`
const PAUSE_DISCONNECT_TIMEOUT = 180_000; // how long to stay paused before automatically disconnecting to save network etc

export default class StreamOutput
{
  /** the WHIP URL to negotiate SDP with */
  #url; // string

  /** the RTCPeerConnection config to use */
  #config; // args to RTCPeerConnection constructor

  /** the audio output */
  #player; // AudioOutput

  /** current state of the audio stream */
  #currentState; // string

  /** list of callbacks to run when `this.#currentState` changes */
  #onStateChangeCallbacks; // array of (event) => void

  /** the active peer connection */
  #peer; // RTCPeerConnection

  /** the active incoming audio stream */
  #stream; // MediaStream

  /** timeout handle to trigger auto-disconnect if the user leaves the stream paused for a while */
  #pauseDisconnectTimeout;

  constructor({ url, config, player }) {
    this.#url = url;
    this.#config = config;
    this.#player = player;

    this.#onStateChangeCallbacks = [];

    this.#player.addStateChangeListener(this.#onAudioStateChange.bind(this));
  }

  /**
   * add a state change listener
   * @param callback 
   */
  addStateChangeListener(callback) {
    this.#onStateChangeCallbacks.push(callback);
  }

  /**
   * remove the given state change listener
   * @param callback 
   */
  removeStateChangeListener(callback) {
    this.#onStateChangeCallbacks = this.#onStateChangeCallbacks.filter(cb => cb !== callback);
  }

  /**
   * trigger a state change event (only if the new state differs from the existing one)
   * @param state 
   */
  #doStateChange(state) {
    console.log('STREAM STATE:', this.#currentState, '->', state);
    
    if(this.#currentState !== state) this.#currentState = state;

    this.#onStateChangeCallbacks.forEach(callback => callback(state));
  }

  /**
   * create and initialise `this.#peer`
   * 
   * make sure to call `this.#peer.close()` beforehand if you need a new instance - `this.start()` will do that
   */
  #makePeerConnection() {
    this.#peer = new RTCPeerConnection(this.#config);

    this.#peer.addEventListener('connectionstatechange', this.#onConnectionStateChange.bind(this));
    this.#peer.addEventListener('iceconnectionstatechange', this.#onIceConnectionStateChange.bind(this));
    this.#peer.addEventListener('icegatheringstatechange', this.#onIceGatheringStateChange.bind(this));
    this.#peer.addEventListener('signalingstatechange', this.#onSignalingStateChange.bind(this));

    this.#peer.addEventListener('icecandidate', this.#onIceCandidate.bind(this));
    this.#peer.addEventListener('icecandidateerror', this.#onIceCandidateError.bind(this));

    this.#peer.addEventListener('track', this.#onTrack.bind(this));

    this.#peer.addEventListener('negotiationneeded', this.#onNegotiationNeeded.bind(this));
  }

  /**
   * attempt to connect the audio stream via the WHIP url given to the constructor - ie `this.#url` - and start playback once it's ready
   * 
   * this will resolve when the `RTCPeerConnection.connectionState === 'connected'` via a listener in `this.#startConnection()` for `this.#currentState === 'connected'`
   * 
   * it will reject if anything fails or any timeouts are hit
   */
  async start() {
    if(this.#peer) await this.stop();

    this.#doStateChange('connecting');

    this.#makePeerConnection();

    try {
      await this.#startConnection();
    }
    catch(e) {
      this.#doStateChange('error');

      throw e;
    }
  }

  /**
   * stop the audio playback and close the connection
   * 
   * this will resolve when the `RTCPeerConnection.connectionState === 'closed'`
   *
   * it will reject if disconnection hits a timeout but that's probably fine to ignore?
   */
  async stop() {
    if(!this.#peer) return;

    this.#player.pause();

    this.#doStateChange('disconnecting');

    try {
      await this.#stopConnection();
    }
    catch(e) {
      this.#doStateChange('disconnected');
      console.log('error occured while stopping stream:', e);
    }

    this.#peer = null;
  }

  /**
   * kick off the connection process and wait for it to complete
   * @returns Promise
   */
  #startConnection() {
    return new Promise((resolve, reject) => {
      let didResolve = false;

      // Kick off the whole connection process by adding a receiver!
      // Doing this triggers a "negotiationneeded" event, thereby calling this.#onNegotiationNeeded()
      this.#peer.addTransceiver('audio', {
        direction: 'recvonly',
      });

      // Listen for state changes on ourself so we can resolve/reject accordingly
      try {
        const onStateChange = (state) => {
          if(state === 'connected') {
            resolve(null);
            didResolve = true;

            this.removeStateChangeListener(onStateChange);
          }
          else if(state === 'error' || state === 'disconnected') {
            reject(new Error('Failed to get connect'));
            didResolve = true;
          }
        };

        this.addStateChangeListener(onStateChange);
      }
      catch(e) {
        reject(e);
        didResolve = true;
      }

      // Fail if it takes too long
      setTimeout(() => {
        if(!didResolve) reject(new Error('Connection timed out'));
      }, CONNECTION_TIMEOUT);
    });
  }

  /**
   * close the connection and wait for it to complete
   * @returns Promise
   */
  #stopConnection() {
    return new Promise((resolve, reject) => {
      let didResolve = false;

      // Listen for connection to close
      try {
        const onStateChange = (event) => {
          if(this.#peer.connectionState === 'closed') {
            resolve(null);
            didResolve = true;

            this.#peer.removeEventListener('connectionstatechange', onStateChange);
          }
        };

        this.#peer.addEventListener('connectionstatechange', onStateChange);
      }
      catch(e) {
        reject(e);
        didResolve = true;
      }

      // Start the closure
      fetch(this.#url, {
        method: "DELETE",
        mode: "cors",
      });

      this.#peer.close();

      // Fail if it takes too long
      setTimeout(() => {
        if(!didResolve) reject(new Error('Connection closure timed out'));
      }, CONNECTION_CLOSURE_TIMEOUT);
    });
  }

  /**
   * negotiate via SDP with the WHIP url given to the constructor - ie `this.#url`
   */
  #onNegotiationNeeded() {
    console.log('onNegotiationNeeded', this, ...arguments);

    (async () => {
      try {
        const offer = await this.#offerAndConnect();

        await this.#waitForGathering();

        await this.#negotiateAndAnswer(offer.sdp);
      }
      catch(e) {
        this.#doStateChange('error');

        throw e;
      }
    })();
  }

  /**
   * generate a local SDP offer
   * @returns RTCSessionDescription
   */
  async #offerAndConnect() {
    let offer = await this.#peer.createOffer({ });

    if(offer.sdp.match(/^a=fmtp:.*useinbandfec=/m) && !offer.sdp.match(/^a=fmtp:.*stereo=/m)) {
      // Make sure we're requesting stereo audio cos Google Chrome has a known bug where it doesn't do it unless you manually adjust the SDP
      offer = new RTCSessionDescription({
        sdp: offer.sdp.replace(/(^a=fmtp:.*)(useinbandfec=)/m, "$1stereo=1;$2"),
        type: offer.type,
      });
    }
    
    await this.#peer.setLocalDescription(offer); // this kicks off the ICE gathering

    console.log('LOCAL SDP OFFER', offer.sdp);

    return offer;
  }

  /**
   * wait for ICE gathering to complete or reject if the timeout is hit
   * @returns Promise
   */
  #waitForGathering() {
    return new Promise((resolve, reject) => {
      let didResolve = false;

      // Listen for ICE gathering to complete
      try {
        const onStateChange = (event) => {
          if(this.#peer.iceGatheringState === 'complete') {
            resolve(null);
            didResolve = true;

            this.#peer.removeEventListener('icegatheringstatechange', onStateChange);
          }
        };

        this.#peer.addEventListener('icegatheringstatechange', onStateChange);
      }
      catch(e) {
        reject(e);
        didResolve = true;
      }

      // Fail if it takes too long
      setTimeout(() => {
        if(!didResolve) reject(new Error('ICE gathering timed out'));
      }, ICE_GATHERING_TIMEOUT);
    });
  }

  /**
   * send the local SDP offer to the WHIP url and accept the remote SDP answer
   * @param offerSdp 
   */
  async #negotiateAndAnswer(offerSdp) {
    const response = await fetch(this.#url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/sdp',
      },
      body: offerSdp
    });

    if(response.status === 201) {
      // Got a valid answer
      const answerSdp = await response.text();

      const answer = new RTCSessionDescription({
        sdp: answerSdp,
        type: 'answer',
      });

      console.log('REMOTE SDP ANSWER', answer.sdp);

      await this.#peer.setRemoteDescription(answer);
    }
    else {
      // Got something else
      const error = await response.text();

      throw new Error(`Got unexpected SDP answer (code ${response.status}): ${error}`);
    }
  }

  #onAudioStateChange(state) {
    switch(state) {
      case 'playing':
        // clear the auto-disconnect timer
        clearTimeout(this.#pauseDisconnectTimeout);
        break;

      case 'paused':
        // start a timer to auto-disconnect if the user's been paused for long enough
        this.#pauseDisconnectTimeout = setTimeout(() => this.stop(), PAUSE_DISCONNECT_TIMEOUT);
        break;

      default:
        //
        break;
    }
  }

  #onConnectionStateChange() {
    console.log('onConnectionStateChange', this.#peer.connectionState);

    switch(this.#peer.connectionState) {
      case 'new':
      case 'connecting':
        break;

      case 'connected':
        this.#doStateChange('connected');

        this.#player.play(); // start playing immediately
        break;

      case 'disconnected':
      case 'closed':
        // If the streamer hits Stop then gotta do a hardcore reboot cos the transceiver receiver's transport will be closed
        this.#doStateChange('disconnected');

        // See if any of the receivers are closed
        const needsHardRestart = this.#peer.getReceivers()
          .reduce((status, receiver) => status || receiver.transport.state === 'closed', false);

				if(needsHardRestart) {
					// Gotta do a hard restart
					console.log('doing hard restart');

					this.start();
				}
        break;

      case 'failed':
        // "failed" is permanent ("disconnected" may come back by itself) but unlike "closed" it might be recoverable
        this.#doStateChange('error');

        console.log('renegotiating');
        this.#peer.restartIce();
        break;

      default:
        //
        break;
    }
  }

  #onIceConnectionStateChange() {
    console.log('onIceConnectionStateChange', this.#peer.iceConnectionState);
  }

  #onIceGatheringStateChange() {
    console.log('onIceGatheringStateChange', this.#peer.iceGatheringState);
  }

  #onSignalingStateChange() {
    console.log('onSignalingStateChange', this.#peer.signalingState);
  }

  #onIceCandidate() {
    console.log('onIceCandidate', this, ...arguments);
  }

  #onIceCandidateError() {
    console.log('onIceCandidateError', this, ...arguments);
  }

  #onTrack({ streams }) {
    const [ stream ] = streams;
   
    console.log('onTrack', this.#stream, '->', stream);

    this.#stream = stream;
    this.#player.applyStream(this.#stream);
  }
}