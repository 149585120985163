import { loadStripe, Stripe } from "@stripe/stripe-js";

interface StripeClients {
  [ key: string ]: Stripe | null;
};

const stripe: StripeClients = {
  //
};

export async function loadStripeClient(key: string) {
  if(stripe[key] === undefined) {
    stripe[key] = null; // set to null first to avoid race condition while stripe loads
    stripe[key] = await loadStripe(key);
  }

  return stripe[key];
}