import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import Modal from "../components/Modal";
import "./Geofence.css";

type GeofenceValue = {
  hasPermission: boolean;
  canAskPermission: boolean;
  position: GeolocationPosition | null;
  getPosition: () => Promise<void>;
};

const GeofenceContext = createContext<GeofenceValue>({
  hasPermission: false,
  canAskPermission: false,
  position: null,
  getPosition: () => new Promise((resolve) => { resolve() }),
});

export function useGeofence() {
  return useContext(GeofenceContext);
}

function GeofenceRequestPopup() {
  const { getPosition } = useGeofence();

  // TODO: put a loading spinner on the Allow button for while it's fetching the user's location

  const doRequest = useCallback(async () => {
    try {
      await getPosition();
    }
    catch(e) {
      // Print to console because the actual user-friendly error is handled by <GeofenceDeniedPopup/>
      console.error('Failed to get location: ', e);
    }
  }, [ getPosition ]);

  return (
    <Modal dialogStyle="Geofence">
      <p className="Geofence-title">SportsBug needs your location</p>
      <p>Live streaming is restricted to the venue, and SportsBug needs access to your location to determine if you're inside the venue precinct.</p>
      <p>If you're outside the venue you can still listen via The Varsity Network app.</p>
      <button onClick={doRequest} className="button">Allow access to your location</button>
      <a href="https://thevarsitynetwork.com" className="button">Open The Varsity Network</a>
    </Modal>
  );
}

function GeofenceDeniedPopup() {
  return (
    <Modal dialogStyle="Geofence">
      <p className="Geofence-title">SportsBug needs your location</p>
      <p>Unfortunately, your browser is not allowing SportsBug to request your current location.</p>
      <p>Please refresh this page to try again, or open your browser or device settings and allow SportsBug access to your location, and then refresh this page.</p>
      <p>Live streaming is restricted to the venue, and SportsBug needs access to your location to determine if you're inside the venue precinct.</p>
      <p>If you're outside the venue you can still listen via The Varsity Network app.</p>
    </Modal>
  );
}

type GeofenceProps = {
  children: ReactNode;
};

export default function Geofence({ children }: GeofenceProps) {
  const [ hasPermission, setHasPermission ] = useState(false);
  const [ canAskPermission, setCanAskPermission ] = useState(false);

  const [ position, setPosition ] = useState<GeolocationPosition|null>(null);

  const getPosition = useCallback(() => {
    return new Promise<void>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setHasPermission(true);
          setCanAskPermission(false);
          setPosition(position);
          resolve();
        },
        (error) => {
          setHasPermission(false);
          setCanAskPermission(false);
          reject(error);
        },
        {
          enableHighAccuracy: true,
        }
      );
    });
  }, []);

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' })
      .then(({ state }) => {
        switch(state) {
          case 'granted':
            setHasPermission(true);
            getPosition();
            break;

          case 'prompt':
            setCanAskPermission(true);
            break;

          case 'denied':
            break;
        }
      });
  }, [ getPosition ]);

  return (
    <GeofenceContext.Provider value={{ hasPermission, canAskPermission, position, getPosition }}>
      {children}

      {!hasPermission
        ? canAskPermission
          ? <GeofenceRequestPopup/>
          : <GeofenceDeniedPopup/>
        : null
      }
    </GeofenceContext.Provider>
  );
}