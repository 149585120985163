import { Team } from "../types/team";
import "./Teams.css";

type TeamsProps = {
  home: Team;
  away: Team;
};

export default function Teams({ home, away }: TeamsProps) {
  return (
    <div className="Teams">
      <div className="Teams-inner Teams-inner--home">
        <img
          src={home.logo}
          alt={home.short_name}
          className="Teams-logo"
          />

        <span className="Teams-title">{home.long_name}</span>
      </div>

      <div className="Teams-separator">
        <p className="Teams-separator-label">vs</p>
      </div>

      <div className="Teams-inner Teams-inner--away">
        <span className="Teams-title">{away.long_name}</span>

        <img
          src={away.logo}
          alt={away.short_name}
          className="Teams-logo"
          />
      </div>
    </div>
  );
}