import { useCallback, useEffect, useRef, useState } from "react";
import StreamOutput from "../../../core/stream/StreamOutput";
import AudioOutput from "../../../core/stream/AudioOutput";
// import iconPlayDark from "../../../assets/icons/play-dark.svg";

interface ButtonInner {
  [key: string]: string;
}

const BUTTON_INNER: ButtonInner = {
  disconnected: `
    Tap to start playing
    `,
  pending: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="spinner"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M304 48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zm0 416c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM48 304c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48zm464-48c0-26.5-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48s48-21.5 48-48zM142.9 437c18.7-18.7 18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zm0-294.2c18.7-18.7 18.7-49.1 0-67.9S93.7 56.2 75 75s-18.7 49.1 0 67.9s49.1 18.7 67.9 0zM369.1 437c18.7 18.7 49.1 18.7 67.9 0s18.7-49.1 0-67.9s-49.1-18.7-67.9 0s-18.7 49.1 0 67.9z"/></svg>
    Loading&hellip;
    `,
  playing: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M48 64C21.5 64 0 85.5 0 112V400c0 26.5 21.5 48 48 48H80c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H48zm192 0c-26.5 0-48 21.5-48 48V400c0 26.5 21.5 48 48 48h32c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48H240z"/></svg>
    Playing
    `,
  paused: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"/></svg>
    Paused
    `,
  error: `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c13.3 0 24 10.7 24 24V264c0 13.3-10.7 24-24 24s-24-10.7-24-24V152c0-13.3 10.7-24 24-24zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z"/></svg>
    Error
    `,
};

type Config = {
  peer: object;
};

type PlayerControlProps = {
  gameId: number;
};

export default function PlayerControl({ gameId }: PlayerControlProps) {
  const peer = useRef<StreamOutput|null>(null);
  const player = useRef<AudioOutput|null>(null);

  const [ config, setConfig ] = useState<Config|null>(null);

  const [ state, setState ] = useState('disconnected');
  const [ isPlaying, setPlaying ] = useState('pending');

  // const [ delay, setDelay ] = useState(0);

  const fetchConfig = useCallback(async () => {
    const response = await fetch('/stream/config');

    if(response.ok) {
      const data = await response.json();

      setConfig(data as Config);
    }
  }, [ ]);

  useEffect(() => {
    fetchConfig();
  }, [ fetchConfig ]);

  const startStream = useCallback(() => {
    player.current = new AudioOutput();
    player.current.addStateChangeListener(setPlaying);

    peer.current = new StreamOutput({
      url: `/stream/${gameId}`,
      config: config?.peer,
      player: player.current,
    });
    peer.current.addStateChangeListener(setState);

    peer.current.start();
  }, [ gameId, config ]);

  const togglePlayback = useCallback(() => {
    if(!peer.current) {
      startStream();
    }
    else if(state === 'disconnected') {
      peer.current.start();
    }
    else if(player.current) {
      switch(isPlaying) {
        case 'paused':
          player.current.play();
          break;

        case 'playing':
          player.current.pause();
          break;
      }
    }
  }, [ peer, player, state, isPlaying, startStream ]);

  // useEffect(() => {
  //   if(player.current) player.current.setDelay(delay);
  // }, [ player, delay ]);

  let button = 'pending';
  switch(state) {
    case 'disconnected':
      button = 'disconnected';
      break;

    case 'error':
      button = 'error';
      break;

    case 'connected':
      switch(isPlaying) {
        case 'paused':
          button = 'paused';
          break;

        case 'playing':
          button = 'playing';
          break;
      }
      break;
  }

  return (
    <>
      <button
        type="button"
        className={`button ${button}`}
        onClick={togglePlayback}
        dangerouslySetInnerHTML={{ __html: BUTTON_INNER[button] }}
        />
      {/* <button className="button">
        <img
          src={iconPlayDark}
          alt="Play"
          className="button-icon"
          />
        <span>Tap to start playing</span>
      </button> */}

      <hr/>

      <p className="Details-warning">If an error occurs or your stream drops out just refresh this page - you won't have to purchase again.</p>
    </>
  )
}