const DELAY_CHANGE_DEBOUNCE = 1_000; // how much to debounce delay changes by to

export default class AudioOutput
{
  /** current state of the audio stream */
  #currentState; // string

  /** list of callbacks to run when `this.#currentState` changes */
  #onStateChangeCallbacks; // array of (event) => void

  /** the audio pipeline context */
  #context; // AudioContext

  /** current input source */
  #sourceNode; // MediaStreamAudioSourceNode

  /** delay node for tv sync */
  #delayNode; // DelayNode

  /** final output destination */
  #outputNode; // MediaStreamAudioDestinationNode

  /** the audio player itself */
  #player; // Audio

  /** timeout handle for debouncing delay changes */
  #delayDebounce;

  constructor() {
    this.#onStateChangeCallbacks = [];

    this.#context = new AudioContext();

    this.#outputNode = new MediaStreamAudioDestinationNode(this.#context, {
      //
    });

    this.#delayNode = new DelayNode(this.#context, {
      delayTime: 0, // initial
      maxDelayTime: 60, // max
    });
    this.#delayNode.connect(this.#outputNode);

    // this.#sourceNode is created by this.applyStream()

    this.#player = new Audio();
    this.#player.srcObject = this.#outputNode.stream;
  }

  /**
   * add a state change listener
   * @param callback 
   */
  addStateChangeListener(callback) {
    this.#onStateChangeCallbacks.push(callback);
  }

  /**
   * remove the given state change listener
   * @param callback 
   */
  removeStateChangeListener(callback) {
    this.#onStateChangeCallbacks = this.#onStateChangeCallbacks.filter(cb => cb !== callback);
  }

  /**
   * trigger a state change event (only if the new state differs from the existing one)
   * @param state 
   */
  #doStateChange(state) {
    console.log('AUDIO STATE:', this.#currentState, '->', state);
    
    if(this.#currentState !== state) this.#currentState = state;

    this.#onStateChangeCallbacks.forEach(callback => callback(state));
  }

  /**
   * set the given stream as the main sourceNode
   */
  applyStream(stream) {
    console.log('applystream', stream);

    if(this.#sourceNode) {
      this.#sourceNode.disconnect();
      this.#sourceNode = null;
    }

    this.#sourceNode = new MediaStreamAudioSourceNode(this.#context, {
      mediaStream: stream,
    });
    this.#sourceNode.connect(this.#delayNode);

    // Hack for an old bug in Chrome where it doesn't play WebRTC audio through an AudioContext
    // (this work around pipes it through to the device speakers but muted, which is enough to kick off the AudioContext)
    let fake = new Audio();
    fake.muted = true;
    fake.srcObject = stream;
    fake.addEventListener('canplaythrough', () => {
        fake = null;
    });
  }

  /**
   * transition the delay amount
   */
  setDelay(delay) {
    clearTimeout(this.#delayDebounce);

    this.#delayDebounce = setTimeout(() => {
      console.log('changing delay', this.#delayNode.delayTime.value, ' -> ', delay);

      const diff = delay - this.#delayNode.delayTime.value;

      const endTime = this.#context.currentTime + Math.abs(diff);

      this.#delayNode.delayTime.linearRampToValueAtTime(delay, endTime);
    }, DELAY_CHANGE_DEBOUNCE);
  }

  /**
   * start playing the audio track
   */
  play() {
    if(!this.#player.paused) return;

    this.#doStateChange('playing');

    this.#player.play(); // this is async and may not actually start playing
  }

  /**
   * stop playing the audio track
   *
   * this also starts a timer that'll auto-disconnect the stream if the user leaves it paused for a while so we can save network usage and other resources
   */
  pause() {
    if(this.#player.paused) return;

    this.#doStateChange('paused');

    this.#player.pause();
  }
}