import { ReactNode } from "react";
import "./Modal.css";

type ModalProps = {
  outerStyle?: string;
  innerStyle?: string;
  dialogStyle?: string;
  children: ReactNode;
}

export default function Modal({ outerStyle, innerStyle, dialogStyle, children }: ModalProps) {
  return (
    <div className={`Modal ${outerStyle}`}>
      <div className={`Modal-inner ${innerStyle}`}>
        <div className={`Modal-dialog ${dialogStyle}`}>
          {children}
        </div>
      </div>
    </div>
  )
}