import React from 'react';
import ReactDOM from 'react-dom/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import './index.css';
import App from './app/App';
import Geofence from './app/Geofence';

dayjs.extend(utc);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Geofence>
      <App />
    </Geofence>
  </React.StrictMode>
);
