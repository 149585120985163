import BaseCard from "./BaseCard";
import "./LiveNowCard.css";
import iconSpeakerDark from '../../assets/icons/speaker-dark.svg';
import iconVarsity from '../../assets/icons/varsity.svg';
import { Game } from "../../types/game";

type LiveNowCardProps = Game & {
  onClick: () => void;
};

export default function LiveNowCard({ onClick, home, away, venue }: LiveNowCardProps) {
  return (
    <BaseCard title={`LIVE NOW @ ${venue}`}>
      <div className="LiveNowCard-teams">
        <img
          src={home.logo}
          alt={home.short_name}
          className="LiveNowCard-team-logo"
          />

        <p className="LiveNowCard-title">
          <span className="LiveNowCard-title-team">{home.long_name}</span>
          {` vs `}
          <span className="LiveNowCard-title-team">{away.long_name}</span>
        </p>

        <img
          src={away.logo}
          alt={away.short_name}
          className="LiveNowCard-team-logo"
          />
      </div>

      <div className="LiveNowCard-actions">
        <a href="https://thevarsitynetwork.com" className="button">
          <img
            src={iconVarsity}
            alt="The Varsity Network"
            className="button-icon"
            />
          <span>Find Out More</span>
        </a>
        <button className="button" onClick={onClick}>
          <img
            src={iconSpeakerDark}
            alt="Speaker"
            className="button-icon"
            />
          <span>Listen Live</span>
        </button>
      </div>
    </BaseCard>
  );
}