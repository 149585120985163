import { Stripe, StripeElements } from "@stripe/stripe-js";
import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { loadStripeClient } from "../../../core/stripe";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

type Intent = {
  id: number;
  payment_key: string;
  payment_token: string;
  customer_name: string;
  customer_email: string;
};

type CustomerDetailsProps = {
  gameId: number;
  onIntent: (intent: Intent) => void;
  isChecking: boolean;
};

function CustomerDetails({ gameId, onIntent, isChecking }: CustomerDetailsProps) {
  const form = useRef<HTMLFormElement>(null);

  const [ customerName, setCustomerName ] = useState('');
  const [ customerEmail, setCustomerEmail ] = useState('');

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);

  const submit = useCallback(async (event: FormEvent) => {
    event.preventDefault();

    setSubmitting(true);
    setError(null);

    try {
      const input = new FormData(form.current as HTMLFormElement);

      const response = await fetch(`/purchase/${gameId}`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        body: input,
      });

      const body = await response.text();

      if(response.ok) {
        const output = JSON.parse(body);

        onIntent(output as Intent);
      }
      else if(response.status >= 500) {
        // Don't print any output for server errors
        setError('A server error occurred');
      }
      else {
        // Other errors are fine to print
        setError(body || 'An error occurred');
      }
    }
    catch(e) {
      console.error('Failed to submit customer info:', e);
    }

    setSubmitting(false);
  }, [ gameId, onIntent ]);

  const disabled = (isSubmitting || isChecking);

  return (
    <form
      ref={form}
      onSubmit={disabled ? () => {} : submit}
      >
      <p>Enter your details below to start this&nbsp;stream</p>

      <input
        type="text"
        name="customer_name"
        className="textfield"
        placeholder="Your Name"
        value={customerName}
        onChange={e => setCustomerName(e.target.value)}
        />

      <input
        type="email"
        name="customer_email"
        className="textfield"
        placeholder="Email Address"
        value={customerEmail}
        onChange={e => setCustomerEmail(e.target.value)}
        />

      <button
        type="submit"
        className="button"
        disabled={disabled}
        >
        Start Listening &rarr;
      </button>

      {error
        ? <p className="error">{error}</p>
        : null
      }
    </form>
  );
}

type PaymentFieldsProps = {
  intent: Intent;
  onPaid: (id: number) => void;
};

function PaymentFields({ intent, onPaid }: PaymentFieldsProps) {
  const form = useRef<HTMLFormElement>(null);

  const stripe = useStripe();
  const elements = useElements();

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ error, setError ] = useState<string | null>(null);

  const submit = useCallback(async (event: FormEvent) => {
    event.preventDefault();

    setSubmitting(true);
    setError(null);

    const return_url = new URL(window.location.href); // return back here

    // this also returns a `paymentIntent` property but we're not using it
    const { error } = await (stripe as Stripe).confirmPayment({
      elements: elements as StripeElements,
      confirmParams: {
        return_url: return_url.toString(),
      },
      redirect: 'if_required',
    });

    if(error) setError(error.message || `An error occurred: ${error.type}`);
    else onPaid(intent.id);

    setSubmitting(false);
  }, [ intent, onPaid, stripe, elements ]);

  const disabled = (!stripe || isSubmitting);

  return (
    <form
      ref={form}
      onSubmit={disabled ? () => {} : submit}
      >
      <PaymentElement
        options={{
          defaultValues: {
            billingDetails: {
              email: intent.customer_email,
              name: intent.customer_name,
            },
          },
        }}
        />

      <button
        type="submit"
        className="button"
        disabled={disabled}
        >
        Complete Payment &rarr;
      </button>

      {error
        ? <p className="error">{error}</p>
        : null
      }
    </form>
  );
}

type PaymentFormProps = PaymentFieldsProps & {
  //
};

function PaymentForm({ intent, ...props }: PaymentFormProps) {
  const [ stripe, setStripe ] = useState<Stripe | null>(null);

  useEffect(() => {
    if(intent.payment_key) loadStripeClient(intent.payment_key).then(setStripe);
    else setStripe(null);
  }, [ intent ]);

  return (
    <Elements
      stripe={stripe}
      options={{
        clientSecret: intent.payment_token,
      }}
      >
      <PaymentFields
        intent={intent}
        {...props}
        />
    </Elements>
  );
}

type PurchaseFormProps = {
  gameId: number;
  onPurchased: () => void;
};

export default function PurchaseForm({ gameId, onPurchased }: PurchaseFormProps) {
  const [ intent, setIntent ] = useState<Intent | null>(null);

  const [ isChecking, setChecking ] = useState(false);

  const paid = useCallback((id: number) => {
    // Save the ID to the browser so the user can come back
    window.localStorage.setItem(`game_${gameId}_payment`, `${id}`); // gotta convert ID number to string to satisfy typescript

    onPurchased();
  }, [ gameId, onPurchased ]);

  useEffect(() => {
    const id = window.localStorage.getItem(`game_${gameId}_payment`);

    if(id) {
      setChecking(true);

      // Check if the saved purchase ID is valid
      const input = new FormData();
      input.set('id', id);
      input.set('game', `${gameId}`); // gotta convert ID to string cos typescript

      fetch(`/purchase/check`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
        },
        body: input,
      })
        .then((response) => {
          if(response.ok) onPurchased();
        })
        .catch((error) => {
          // Don't care about error response
        });
    }
  }, [ gameId, onPurchased ]);

  return (
    <>
      {!intent
        ? <CustomerDetails
          gameId={gameId}
          onIntent={setIntent}
          isChecking={isChecking}
          />
        : <PaymentForm
          intent={intent}
          onPaid={paid}
          />
      }
    </>
  );
}