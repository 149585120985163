import { ReactNode } from "react";
import './BaseCard.css';

type BaseCardProps = {
  title?: string;
  outerStyle?: string;
  innerStyle?: string;
  children?: ReactNode;
}

export default function BaseCard({ title, outerStyle, innerStyle, children }: BaseCardProps) {
  return (
    <div className={`Card ${outerStyle}`}>
      {title
        ? <div className="Card-header">
            <p className="Card-title">{title}</p>
          </div>
        : null
      }

      <div className={`Card-inner ${innerStyle}`}>
        {children}
      </div>
    </div>
  );
}
