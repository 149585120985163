import { useCallback, useState } from 'react';
import logoSportsbug from '../assets/brand/logo-sportsbug.svg';
import logoVarsity from '../assets/brand/logo-varsity.svg';
import './App.css';
import Schedule from "./Schedule";
import { Game } from '../types/game';
import Details from './Details';

function AppHeader() {
  return (
    <div className="App-header">
      <div className="App-logos">
        <img
          src={logoSportsbug}
          className="App-logo-sportsbug"
          alt="SportsBug"
          />

        <span>&times;</span>

        <img
          src={logoVarsity}
          className="App-logo-varsity"
          alt="The Varsity Network"
          />
      </div>

      <p className="App-tagline">Listen live with no delay</p>
    </div>
  );
}

function App() {
  const [ game, setGame ] = useState<Game|null>(null);

  // TODO: implement proper History API handling so the browser back button works

  const doSelect = useCallback((game: Game) => {
    window.location.hash = `#game=${game.id}`;
    setGame(game);
  }, []);

  return (
    <div className="App">
      <div className="App-inner">
        <AppHeader/>

        {game
          ? <Details {...game}/>
          : <Schedule onSelect={doSelect}/>
        }
      </div>
    </div>
  );
}

export default App;
